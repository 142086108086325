import React from 'react';
import {Box, Table, Tbody, Button, Heading} from '@tempo/core';
import {
	DeezerLogoIcon,
	MailIcon,
	CalendarIcon,
	ClockOutlinedIcon,
	PremiumOutlinedIcon,
	LockIcon,
	CopyIcon,
} from '@tempo/icons';
import {TableHeading} from '@components/Table/TableHeading';
import {TableRow} from '@components/Table/TableRow';
import {formatDate} from '@utils/date';

export interface CustomerData {
	id: string;
	type: string;
	attributes: {
		_id: string;
		userId: number;
		legacyPartnerId: number;
		insertDate: string;
		updateDate: string;
		linked: boolean;
		linkDate: string;
		active: boolean;
		legacyOfferId: number;
		offerStartDate: string;
		offerEndDate: string;
	};
	relationships: {
		offer: {
			data: {
				type: string;
				id: string;
			};
		};
		user: {
			data: {
				type: string;
				id: string;
			}[];
		};
	};
}

export interface CustomerIncluded {
	id: string;
	type: string;
	attributes: {
		_id: string;
		legacyOfferId?: number;
		name?: string;
		partnerId?: number;
		username?: string;
		email?: string;
	};
}

export interface CustomerDetailsProps {
	data: CustomerData;
	included?: CustomerIncluded[];
}

export type CustomerDetailsTableProps = CustomerDetailsProps & {
	openForceActivationModal: () => void;
	openUnlinkModal: () => void;
	openSendPasswordLinkModal: () => void;
	openCopyPasswordLinkModal: () => void;
	openSendActivationLinkModal: () => void;
};

const CustomerDetailsTable: React.FC<CustomerDetailsTableProps> = ({
	data,
	included,
	openForceActivationModal,
	openUnlinkModal,
	openSendPasswordLinkModal,
	openCopyPasswordLinkModal,
	openSendActivationLinkModal,
}) => {
	const getUser = () => {
		return included?.find((item) => item.type === 'User');
	};
	const getOffer = () => {
		return included?.find((item) => item.type === 'Offer');
	};

	const user = getUser() || null;
	const offer = getOffer() || null;

	return (
		<Box width="100%" pb="spacing.l">
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				mb="spacing.xl"
			>
				<Heading variant="display.s" data-testid="customer-name">
					{user?.attributes.username || 'User'}
				</Heading>
				{data.attributes.linked ? (
					<Button
						variant="outline"
						onClick={openUnlinkModal}
						data-testid="unlink-button"
					>
						Unlink
					</Button>
				) : (
					<Box>
						<Button
							variant="outline"
							mr="spacing.m"
							onClick={openForceActivationModal}
							data-testid="force-activation-button"
						>
							Force activation
						</Button>
						<Button
							data-testid="send-activation-button"
							onClick={openSendActivationLinkModal}
						>
							Send activation link
						</Button>
					</Box>
				)}
			</Box>
			<Box
				display="grid"
				gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
				gap="spacing.xl"
			>
				<Box>
					<Table
						borderWidth="1px"
						borderStyle="solid"
						borderColor="divider.neutral.primary.default"
						data-testid="deezer-credentials-section"
					>
						<Tbody>
							<TableHeading>Deezer credentials</TableHeading>
							<TableRow
								icon={DeezerLogoIcon}
								label="Deezer pseudo"
								value={user?.attributes.username || '-'}
								dataTestId="deezer-sudo-name"
							/>
							<TableRow
								icon={MailIcon}
								label="Email address"
								value={user?.attributes.email || '-'}
								dataTestId="deezer-email-address"
							/>
							<TableRow
								isLastRow
								icon={LockIcon}
								label="Password"
								buttons={[
									<Button
										disabled={
											user?.attributes.email && data.attributes.linked
												? false
												: true
										}
										key="send"
										leftIcon={<MailIcon />}
										variant="outline"
										size="sm"
										data-testid="send-renewal-link-button"
										onClick={openSendPasswordLinkModal}
									>
										Send renewal link
									</Button>,
									<Button
										disabled={
											user?.attributes.email && data.attributes.linked
												? false
												: true
										}
										key="copy"
										leftIcon={<CopyIcon />}
										variant="outline"
										size="sm"
										data-testid="copy-renewal-link-button"
										onClick={openCopyPasswordLinkModal}
									>
										Copy renewal link
									</Button>,
								]}
							/>
						</Tbody>
					</Table>
				</Box>

				<Box>
					<Table
						width="100%"
						borderWidth="1px"
						borderStyle="solid"
						borderColor="divider.neutral.primary.default"
						data-testid="subscriber-information-section"
					>
						<Tbody>
							<TableHeading>Subscriber info</TableHeading>
							<TableRow
								icon={DeezerLogoIcon}
								label="Subscriber ID"
								value={data.attributes._id || '-'}
								dataTestId="subscriber-id"
							/>
							<TableRow
								icon={DeezerLogoIcon}
								label="Deezer ID"
								value={data.attributes.userId || '-'}
								isLastRow
								dataTestId="deezer-id"
							/>
						</Tbody>
					</Table>
				</Box>

				<Box gridColumn={['1', '1', '1 / span 2']}>
					<Table
						borderWidth="1px"
						borderStyle="solid"
						borderColor="divider.neutral.primary.default"
						data-testid="current-offer-section"
					>
						<Tbody>
							<TableHeading>Current offer</TableHeading>
							<TableRow
								icon={ClockOutlinedIcon}
								label="Status"
								isStatus={true}
								isActivated={data.attributes.linked}
								dataTestId="current-offer-status"
							/>
							<TableRow
								icon={PremiumOutlinedIcon}
								label="Offer code"
								value={offer?.attributes.legacyOfferId || '-'}
								dataTestId="current-offer-code"
							/>
							<TableRow
								icon={PremiumOutlinedIcon}
								label="Offer name"
								value={offer?.attributes.name || '-'}
								dataTestId="current-offer-name"
							/>
							<TableRow
								icon={CalendarIcon}
								label="Start date"
								value={formatDate(data.attributes.offerStartDate)}
								dataTestId="current-offer-start-date"
							/>
							<TableRow
								icon={CalendarIcon}
								label="End date"
								value={formatDate(data.attributes.offerEndDate)}
								dataTestId="current-offer-end-date"
							/>
							<TableRow
								icon={CalendarIcon}
								label="Activation date"
								value={formatDate(data.attributes.linkDate)}
								isLastRow
								dataTestId="current-offer-activation-date"
							/>
						</Tbody>
					</Table>
				</Box>
			</Box>
		</Box>
	);
};

export default CustomerDetailsTable;
