import {z} from 'zod';

const emailSchema = (message: string) => z.string().email({message});

const msisdnRegex = /^\+?\d{6,15}$/;

const phoneSchema = (message: string) =>
	z.string().refine((value) => msisdnRegex.test(value), {message});

const emailSearchSchema = emailSchema(
	'The email format is invalid. Check and edit your input before searching again.',
);
const emailForceActivationSchema = emailSchema('The email format is invalid.');
const emailActivationLinkSchema = emailSchema('The email format is invalid.');

const phoneSearchSchema = phoneSchema(
	'The phone number format is invalid. Check and edit your input before searching again.',
);

const validateInputWithSchema = (input: string, schema: z.ZodSchema) => {
	if (input === '') {
		return '';
	}
	const result = schema.safeParse(input.trim());
	return result.success ? '' : result.error.issues[0].message;
};

export const validateSearchInput = (
	input: string,
	type: 'email' | 'msisdn' | 'code',
) => {
	switch (type) {
		case 'email':
			return validateInputWithSchema(input, emailSearchSchema);
		case 'msisdn':
			return validateInputWithSchema(input, phoneSearchSchema);
		case 'code':
		default:
			return '';
	}
};

export const validateForceActivationInput = (input: string) => {
	return validateInputWithSchema(input, emailForceActivationSchema);
};

export const validateActivationLinkInput = (input: string) => {
	return validateInputWithSchema(input, emailActivationLinkSchema);
};
